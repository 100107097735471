import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from 'components';
import {
  CategoryButtonSection,
  CategorySpecificSection,
  FeaturedPosts,
  LatestPostsSection,
  MostPopularSection,
} from 'components/BlogLandingPage';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import * as styles from './blogLandingPage.module.scss';

const BlogPostLandingPage = ({ location, data: staticData }) => {
  const {
    prismicBlogLandingPage: { data: pageData },
    latestPosts,
    categoryPosts,
  } = staticData;

  const {
    featured_post: main,
    sub_featured_post_1: subPostOne,
    sub_featured_post_2: subPostTwo,
    popular_posts: popularPosts,
    category_links: categories,
  } = pageData;

  const pathname = location?.pathname?.replace('/', '');

  const headerProps = {
    title: {
      text: 'Resource Centre',
    },
  };

  // Only show category cards for categories linked in the category button section
  const linkedCategoryUids = categories?.map(category => category?.blog_category?.uid);
  const linkedCategoryPosts = categoryPosts?.group?.filter(group => linkedCategoryUids?.includes(group?.fieldValue));

  const sectionClassnames = {
    blog: styles.blog,
    wrapper: styles.wrapper,
    sectionTitle: styles.sectionTitle,
    postCard: styles.postCard,
    postCardImageWrapper: styles.postCardImageWrapper,
    postCardInfo: styles.postCardInfo,
    postCardTitle: styles.postCardTitle,
    postCardContent: styles.postCardContent,
  };

  const layoutClassnames = {
    headerBody: styles.headerBody,
    wrapper: styles.wrapper,
  };

  return (
    <Layout
      location={location}
      headerProps={headerProps}
      pageData={pageData}
      bodyClassName={styles.blogLandingPage}
      inheritedClassnames={layoutClassnames}
    >
      <FeaturedPosts main={main} subPostOne={subPostOne} subPostTwo={subPostTwo} pathname={pathname} />
      {categories && <CategoryButtonSection categories={linkedCategoryUids} pathname={pathname} />}
      <LatestPostsSection posts={latestPosts?.edges} pathname={pathname} inheritedClassnames={sectionClassnames} />
      <MostPopularSection posts={popularPosts} pathname={pathname} inheritedClassnames={sectionClassnames} />
      <CategorySpecificSection
        categoryPosts={linkedCategoryPosts}
        pathname={pathname}
        inheritedClassnames={sectionClassnames}
      />
    </Layout>
  );
};

export default withPrismicPreview(BlogPostLandingPage);

export const pageQuery = graphql`
  {
    prismicBlogLandingPage {
      prismicId
      _previewable
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        featured_post {
          document {
            ...BlogCardFragment
            ... on PrismicBlogPost {
              data {
                tile_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 800, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          }
        }
        category_links {
          blog_category {
            uid
          }
        }
        sub_featured_post_1 {
          document {
            ...BlogCardFragment
            ... on PrismicBlogPost {
              data {
                tile_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 600, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          }
        }
        sub_featured_post_2 {
          document {
            ...BlogCardFragment
            ... on PrismicBlogPost {
              data {
                tile_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 600, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          }
        }
        popular_posts {
          popular_post {
            document {
              ...BlogCardFragment
              ... on PrismicBlogPost {
                data {
                  tile_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 800, placeholder: BLURRED)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    latestPosts: allPrismicBlogPost(
      filter: { data: { hide_on_production: { eq: false } } }
      sort: { order: DESC, fields: data___date_published }
      limit: 6
    ) {
      edges {
        node {
          ...BlogCardFragment
          data {
            tile_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 600, placeholder: BLURRED)
                }
              }
              url
            }
          }
        }
      }
    }
    categoryPosts: allPrismicBlogPost(sort: { order: DESC, fields: data___date_published }) {
      group(field: data___categories___category___uid, limit: 2) {
        edges {
          node {
            ...BlogCardFragment
            data {
              tile_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 300, placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
        fieldValue
      }
    }
  }

  fragment BlogCardFragment on PrismicBlogPost {
    id
    uid
    data {
      categories {
        category {
          uid
        }
      }
      content {
        text
      }
      title {
        text
      }
      date_published
    }
  }
`;
