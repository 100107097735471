// extracted by mini-css-extract-plugin
export var blog = "blogLandingPage-module--blog--4ZQSv";
export var blogLandingPage = "blogLandingPage-module--blog-landing-page--4nRUm";
export var categoryCards = "blogLandingPage-module--category-cards--Zx9VD";
export var headerBody = "blogLandingPage-module--header-body--FULJn";
export var postCard = "blogLandingPage-module--post-card--ge5qE";
export var postCardContent = "blogLandingPage-module--post-card-content--SrwfM";
export var postCardImageWrapper = "blogLandingPage-module--post-card-image-wrapper--kS5ay";
export var postCardInfo = "blogLandingPage-module--post-card-info--eZH0j";
export var postCardTitle = "blogLandingPage-module--post-card-title--Nl8tY";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var sectionTitle = "blogLandingPage-module--section-title--u0-ZG";
export var wrapper = "blogLandingPage-module--wrapper--0wxDh";